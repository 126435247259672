import React, { Component } from 'react';
import axios from 'axios';
import { Link, Navigate } from "react-router-dom";
import Category from './category';
import List from './list/list';
import Two from './list/two';
import Large from './list/large';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import Loader from '../general/loader';
import Subcategory from './subcategory';
import backArrow from '../../assets/backArrow.png'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'

class Menu extends Component {

    settings = this.props.Settings;
    language = this.props.Language;

    state = {
        categories: [],
        selectCategory: [],
        items: [],
        slide: [],
        loadSlide: false,
        loadCat: false,
        load: false,
        listview: "large",
    }

    async componentDidMount(){

        // Get Items
        let id = window.location.pathname.replace("/menu/", "");

        // Get categories
        const {data} = await axios.get("https://manage.caviarmenu.com/api/categories/besre");
        this.setState({ categories: data }, ()=>{
            this.setState({loadCat: true});
            this.getCategoryName(id);
        });

        {
            // Get Slideshows
            const {data} = await axios.get("https://manage.caviarmenu.com/api/slideshow/besre");
            this.setState({ slide: data }, ()=>{
                this.setState({loadSlide: true});
            });
        }

        {
            const {data} = await axios.get("https://manage.caviarmenu.com/api/items/besre");
            this.setState({ items: data }, ()=>{
                this.setState({load: true});
            });
        }

        this.layoutDirection();

    }

    layoutDirection() {
        if(this.props.Language != "en")
            document.getElementById("itemContainer").style.direction = "rtl";
        else
            document.getElementById("itemContainer").style.direction = "ltr";
    }

    getCategoryName(id) {
        this.state.categories.map(cat => {
            if(cat.id == id) this.setState({ selectCategory: cat });
         });
    }

    handleChange = async e => {
        await this.props.handleLangaugeSubmit(e);
        this.layoutDirection();
    }

    handleCategory = async catId => {
        this.setState({load: false});
        this.getCategoryName(catId);
        this.setState({load: true});
    }

    handleListview = style => {
        this.setState({listview: style});
    }

    render() {
        return (
            <div className='menu-items' onScroll={this.handleScroll}>
                <div className="head">
                            <Link to={"/category"}>
                                <img src={backArrow} className="backward" alt="" />
                            </Link>

                            <div className="language">
                                <button type='submit' className={this.props.Language == "krd"? 'activ': ''} onClick={() => this.handleChange('krd')} > کوردی </button>
                                <button type='submit' className={this.props.Language == "ar"? 'activ': ''} onClick={() => this.handleChange('ar')}> عربی </button>
                                <button type='submit' className={this.props.Language == "en"? 'activ': ''} onClick={() => this.handleChange('en')}> English </button>
                            </div>
                            
                        <img src={this.settings.logo} alt="" />
                    </div>

                <div className='carousel'>
                    {
                        this.state.loadSlide 
                        ? 
                            <Carousel autoPlay={true} showThumbs={false} showStatus={false} showArrows={false} infiniteLoop={true}>
                                { this.state.slide.map(slide=> <div key={slide.id}><img src={slide.image}/></div>)}
                            </Carousel>
                        : 
                        <SkeletonTheme  baseColor="#202020" highlightColor="#444">
                            <Skeleton height={180} />
                        </SkeletonTheme>
                    }
                    
                </div>

                <div className='categories'>

                    {
                        this.state.loadCat ?
                        <> 
                            { this.state.categories.map(cat => <Category key={cat.id}  Data = {cat} Language = {this.props.Language} handleCategory = { this.handleCategory} Active = {this.state.selectCategory.id == cat.id} ></Category>) }
                        </>
                        : 
                        <SkeletonTheme  baseColor="#202020" highlightColor="#444">
                            <Skeleton height={50} />
                        </SkeletonTheme>
                    }
                    
                </div>

                <div className='items' id='itemContainer'>

                <div className="head-listview">
                    <h5 className="category-name">
                    {
                        this.props.Language == "en" ? this.state.selectCategory.name_eng
                        : this.props.Language == "krd" ? this.state.selectCategory.name_krd
                        : this.state.selectCategory.name_ar
                    }
                    </h5>
                    <div className="listview">
                        <i role="button" className={`fas fa-th-list ${this.state.listview == "list"?'active':''}`} onClick={ () => this.handleListview("list")}></i>
                        <i role="button" className={`fas fa-th-large ${this.state.listview == "two"?'active':''}`} onClick={ () => this.handleListview("two")}></i>
                        <i role="button" className={`far fa-square ${this.state.listview == "large"?'active':''}`} onClick={ () => this.handleListview("large")}></i>
                    </div>
                </div>

                {
                    this.state.load ?
                    <> 
                        {
                        this.state.selectCategory.subCat 
                        ? 
                            <div className="subcategories">
                                {
                                    this.state.selectCategory.subCategories.map(
                                        item => <Subcategory key={item.id} Language = {this.props.Language} ListView = {this.state.listview} Category = {item} Items = {this.state.items[item.id]} />
                                    )
                                }
                            </div>
                        :
                        this.state.listview == "list" ? this.state.items[this.state.selectCategory.id].map(item => <List key={item.id}  Data = {item} Language = {this.props.Language} ></List> )
                            : this.state.listview == "two" ? <div className='style-two'> { this.state.items[this.state.selectCategory.id].map( (item, index) => <Two key={item.id}  Data = {item} Language = {this.props.Language} Index={index} Length={ this.state.items[this.state.selectCategory.id].length } ></Two> ) } </div>
                            : <div className='style-large'> { this.state.items[this.state.selectCategory.id].map(item => <Large key={item.id}  Data = {item} Language = {this.props.Language} ></Large> ) } </div>
                        }
                    </>
                    : 
                    <SkeletonTheme  baseColor="#202020" highlightColor="#444">
                        <Skeleton count={5} height={100} />
                    </SkeletonTheme>
                }
                </div>
                
            </div>
        );
    }
}

export default Menu;