import React, { Component } from 'react';
import { Link } from "react-router-dom";
import CatLoading from './catloading';

class Category extends Component {

    data = this.props.Data;
    language = this.props.Language;

    render() {
        return (
            <Link to={"/menu/" + this.data.id} className='categories-item' onClick={ () => this.props.handleCategory(this.data.id)} >
                <div className='categories-icon'>
                    <CatLoading Img={this.data.logo} />
                </div>
                <span className={`categories-name ${this.props.Active?'active':''}`}>
                    {
                        this.props.Language == "en" ? this.data.name_eng
                        : this.props.Language == "krd" ? this.data.name_krd
                        : this.data.name_ar
                    }
                </span>
            </Link>
        );
    }
}

export default Category;