import React, { Component } from 'react';
import axios from 'axios';
import Item from './item';
import { Link, Navigate } from "react-router-dom";
import Loader from './../general/loader';
import backArrow from '../../assets/backArrow.png'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'

class Category extends Component {

    settings = this.props.Settings;
    language = this.props.Language;

    state = {
        categories: [],
        load: false,
        back: false,
        logo: false,
    }

    async componentDidMount() {
        const {data} = await axios.get("https://manage.caviarmenu.com/api/categories/besre");
        this.setState({ categories: data }, ()=>{
            this.setState({load: true});
        });

        setTimeout(() => {
            this.setState({ logo: true });
        }, 2500);

    }

    handleChange = e => {
        this.props.handleLangaugeSubmit(e);
    }


    // back = event => {
    //     event.preventDefault();
    //     this.setState({ back : true });
    // }

    render() {
        return (

            <>
                { this.state.back && <Navigate to="/" replace={true} /> }
                <div className='category'>

                    <div className="head">
                            <Link to={"/"}>
                                <img src={backArrow} className="backward" alt="" />
                            </Link>

                            <div className="language">
                                <button type='submit' className={this.props.Language == "krd"? 'activ': ''} onClick={() => this.handleChange('krd')} > کوردی </button>
                                <button type='submit' className={this.props.Language == "ar"? 'activ': ''} onClick={() => this.handleChange('ar')}> عربی </button>
                                <button type='submit' className={this.props.Language == "en"? 'activ': ''} onClick={() => this.handleChange('en')}> English </button>
                            </div>
                            
                        {
                            this.state.logo? 
                            <img src={this.settings.logo} alt="" />
                            :
                            <SkeletonTheme  baseColor="#202020" highlightColor="#444" duration={10}>
                                <Skeleton height={150} width={150} circle={true} duration={1}  />
                            </SkeletonTheme>
                        }
                    </div>

                    {/* <div className="info">
                        <button>About Us</button>
                        <h3>{this.settings.name}</h3>
                    </div> */}

                    <div className="social">

                        { 
                            this.settings.instgram 
                            &&
                            <a href={this.settings.instgram}><i className="fa-brands fa-instagram"></i></a>
                        }

                        { 
                            this.settings.snapchat
                            &&
                            <a href={this.settings.snapchat}><i className="fa-brands fa-snapchat"></i></a>
                        }

                        { 
                            this.settings.facebook
                            &&
                            <a href={this.settings.facebook}><i className="fa-brands fa-square-facebook"></i></a>
                        }
                        
                        { 
                            this.settings.titok
                            &&
                            <a href={this.settings.titok}><i className="fa-brands fa-tiktok"></i></a>
                        }
                        
                    </div>

                    <div className="category-list">
                        {
                            this.state.load 
                            ?
                                this.state.categories.map( (category, index) => <Item key={index} Data = {category} Language = {this.props.Language} Index={index} Length={this.state.categories.length} ></Item>)
                            : 
                            <SkeletonTheme  baseColor="#202020" highlightColor="#444">
                                <Skeleton count={5} height={80} />
                            </SkeletonTheme>
                        }
                    </div>

                </div>
            </>
        );
    }
}

export default Category;