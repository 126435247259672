import React, { Component } from 'react';
import { Link, Navigate } from "react-router-dom";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'

class Intro extends Component {

    settings = this.props.Settings;
    language = this.props.Language;

    state = {
        navigate: false,
        isLoading: true
    }
    
    componentDidMount() {
        setTimeout(() => {
            this.setState({ isLoading: false });
        }, 2000);
    }

    handleSubmit = event => {
        event.preventDefault();
        this.setState({ navigate : true});
    };
    
    handleChange = lang => {
        this.props.handleLangaugeSubmit(lang);
    }

    render() {

        const { isLoading } = this.state;

        return (
            <>
                { this.state.navigate && <Navigate to="/category" replace={true} /> }


                {
                    isLoading ? 
                    <div className='intro'>
                            <div className="head">
                                <SkeletonTheme  baseColor="#202020" highlightColor="#444" >
                                    <Skeleton height={150} width={150} circle={true} containerClassName="flex-1" />
                                </SkeletonTheme>
                            </div>
                            <SkeletonTheme  baseColor="#202020" highlightColor="#444" >
                                <Skeleton height={200} containerClassName="flex-1" />
                            </SkeletonTheme>
                            <SkeletonTheme  baseColor="#202020" highlightColor="#444" >
                                <Skeleton height={200} containerClassName="flex-1" />
                            </SkeletonTheme>
                    </div>
                    : 
                    <div className='intro' style={ { backgroundImage :`url(${this.settings.introCover})`, backgroundSize: 'cover', backgroundOrigin: 'bottom' } } >
                        <div className="head">
                            <img src={this.settings.logo} alt="" />
                            {/* <h3>{this.settings.name}</h3> */}
                        </div>
                        <form onSubmit={this.handleSubmit} >
                            <button type='submit' onClick={() => this.handleChange('krd')} > کوردی </button>
                            <button type='submit' onClick={() => this.handleChange('ar')}> عربی </button>
                            <button type='submit' onClick={() => this.handleChange('en')}> English </button>
                        </form>

                        <div className="info">

                            <div className="social">

                                { 
                                    this.settings.instgram 
                                    &&
                                    <a href={this.settings.instgram}><i className="fa-brands fa-instagram"></i></a>
                                }

                                { 
                                    this.settings.snapchat
                                    &&
                                    <a href={this.settings.snapchat}><i className="fa-brands fa-snapchat"></i></a>
                                }

                                { 
                                    this.settings.facebook
                                    &&
                                    <a href={this.settings.facebook}><i className="fa-brands fa-square-facebook"></i></a>
                                }

                                { 
                                    this.settings.titok
                                    &&
                                    <a href={this.settings.titok}><i className="fa-brands fa-tiktok"></i></a>
                                }

                            </div>

                            <div>
                                <i className="fa-solid fa-map-location-dot"></i><span>{this.settings.address}</span>
                            </div>
                            <div className='phone'>
                                <i className="fa-solid fa-phone"></i>
                                <div>
                                    <span>{this.settings.phone}</span> 
                                    { 
                                        this.settings.phone_2 
                                        &&
                                        <span>{this.settings.phone_2}</span>
                                    }
                                </div>
                            </div>
                        </div>

                        <div className='send-feedback'>
                            <Link to={"/feedback"}>
                                <i class="fa-solid fa-message"></i> Feedback 
                            </Link>
                        </div>


                    </div>
                }

                
            </>
        );
    }
}

export default Intro;